<template>
	<div>
		<div class="page-title">
			<span style="font-size: 20px;">景点管理</span>
		</div>
		<div class="bg-white p-2 m-2">
			<el-table border class="mt-3" :data="lists" style="width: 100%">
				<el-table-column align="center" width="120" prop="scenic_id" label="景点ID"></el-table-column>
				<el-table-column align="center" prop="name" label="景点名称"></el-table-column>
				<el-table-column align="center" prop="video_id" label="腾讯视频ID"></el-table-column>
				<el-table-column align="center" label="操作" width="150">
					<template slot-scope="scope">
						<el-button type="text" size="mini" @click="openDialog(scope.row)">修改
						</el-button>
					</template>
				</el-table-column>
			</el-table>
			<el-dialog :title="dialogTitle" :visible.sync="dialogVisible" top="3vh" width="650px" height="60%">
				<!-- 修改规则 -->
				<el-form :rules="rules" ref="form" :model="form" label-width="100px">
					<el-form-item label="景点名称" prop="name">
						<el-input v-model="form.name" placeholder="名称"></el-input>
					</el-form-item>
					<el-form-item label="腾讯视频ID" prop="video_id">
						<el-input v-model="form.video_id" placeholder="腾讯视频ID"></el-input>
					</el-form-item>
				</el-form>
				<span slot="footer" class="dialog-footer">
					<el-button type="primary" @click="submit('form')">确 定</el-button>
					<el-button @click="dialogVisible = false">取 消</el-button>
				</span>
			</el-dialog>
		</div>
	</div>
</template>

<script>
	import appRichText from '@/components/app-rich-text.vue';
	export default {
		inject: ['layout'],
		components: {
			appRichText
		},
		data() {
			return {
				activeName: 'basic',
				dialogVisible: false,
				dialogTitle: '编辑景点',
				lists: [],
				form: {
					name: '',
					video_id: '',
				},
				rules: {
					video_id: [{
						required: true,
						message: '请输入腾讯视频ID',
						trigger: 'change'
					}],
				},
				search: {
					s: 'store/scenic/index',
				},
				scenic_id: 0
			}
		},
		created() {
			this.getList();
		},
		methods: {
			// 提交
			submit(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.layout.showLoading()
						this.axios({
							token: true,
							params: {
								s: 'store/scenic/edit',
								scenic_id: this.scenic_id,
							},
							data: {
								scenic: this.form
							},
							method: 'post',
						}).then(res => {
							if (res.data.code == 1) {
								this.$message({
									message: res.data.msg,
									type: 'success'
								});
								this.dialogVisible = false;
								this.getList()
							} else {
								this.$message({
									message: res.data.msg,
									type: 'error'
								});
							}
							this.layout.hideLoading()
						}).catch(err => {
							this.layout.hideLoading()
						})
					}
				})
			},

			// 打开弹出层
			openDialog(item = false) {
				this.layout.showLoading()
				this.axios({
					token: true,
					params: {
						scenic_id: item.scenic_id,
						s: 'store/scenic/edit'
					},
					method: 'get',
				}).then(res => {
					if (res.data.code == 1) {
						this.layout.hideLoading()
						this.form = {
							name: item.name,
							video_id: item.video_id,
						}
						this.scenic_id = item.scenic_id
						// 显示弹出层
						this.dialogVisible = true
					} else {
						this.layout.hideLoading()
						return false;
					}
				}).catch(err => {})
				
			},

			getList() {
				this.layout.showLoading()
				this.axios({
					token: true,
					params: this.search,
					method: 'get',
				}).then(res => {
					this.layout.hideLoading()
					this.lists = res.data.data
				}).catch(err => {
					this.layout.hideLoading()
				})
			},
		},
	}
</script>
<style>

</style>
